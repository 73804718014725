import { autoinject, bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration, activationStrategy, RoutableComponentDetermineActivationStrategy } from 'aurelia-router';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { AlertService } from '../../services/util/Alert';
import { Program } from '../../types/Program';
import { debounced } from '../../util/decorators/debounced';
import { ProgramDelete } from './../../components/programs/program-delete/program-delete';
import { DialogService } from 'aurelia-dialog';
import { DirtyChecker } from '../../types/DirtyChecker';
import { subscribe } from '../../util/decorators/subscribe';
import { ProgramActivity, RecentPrograms } from '../../services/util/RecentPrograms';
import { ensureNumber } from '../../util/numbers/ensureNumber';

@autoinject
@subscribe({
    events: [
        { eventEmitter: 'api.programs', event: 'patched', fn: 'refresh' },
    ],
})
export class DashboardProgramEdit extends DirtyChecker implements RoutableComponentDetermineActivationStrategy {
    public dirtyProps: string[] = ['program']
    validationController: ValidationController;
    router: Router;
    program: Program;
    sort: number;
    programId: number;
    @bindable isMobile: boolean;
    mediaQuery: MediaQueryList;

    constructor(
        private validationFactory: ValidationControllerFactory,
        private api: DatafiProAPI,
        private alerts: AlertService,
        private dialogService: DialogService,
        private recentPrograms: RecentPrograms,
    ) {
        super();
        this.validationController = this.validationFactory.createForCurrentScope();
        this.mediaQuery = window.matchMedia('(max-width: 600px)');
        this.handleMediaQuery(this.mediaQuery);

        this.mediaQuery.addEventListener('change', this.handleMediaQuery);
    }

    determineActivationStrategy(params) {
        if (ensureNumber(params.id) !== this.program.id) {
            return activationStrategy.replace;
        }
    }

    async configureRouter(config: RouterConfiguration, router: Router, params) {
        const programId = parseInt(params.id, 10);
        this.program = programId ?
            await this.api.programs.get(programId, {
                query: { $eager: '[assetTypes, configs, surveys, roles.role]' },
            })
                .then((result) => new Program(result, this.api.files))
                .catch((e) => undefined) :
            new Program({}, this.api.files);

        const settings = { programId: params.id, program: this.program };

        config.map([
            { name: 'program-edit', route: '', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/program-edit'), settings },
            { name: 'projects-edit', route: 'projects-edit', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/projects-edit'), settings },

            { name: 'surveys-list', route: 'surveys/list', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/surveys-list'), settings },
            { name: 'surveys-edit', route: 'surveys/:surveyId', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/surveys-edit'), settings },

            { name: 'assets-list', route: 'assets/list', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/assets/assets-list'), settings},
            { name: 'assets-edit', route: 'assets/edit/:assetId', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/assets/asset-type-edit'), settings},

            { name: 'users-edit', route: 'users', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/users-edit'), settings },

            { name: 'configs-list', route: 'configs/list', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/configs-list'), settings },
            { name: 'configs-edit', route: 'configs/:configId', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/configs-edit'), settings },

            { name: 'auth-edit', route: 'auth-edit', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/auth-edit'), settings },

            { name: 'share-data', route: 'share-data', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/share-data'), settings },

            {  name: 'webhook-list', route: ['webhook/list'], moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/webhook-list'), title: 'Webhooks', settings },
            {  name: 'webhook-create',route: ['webhook/create'], moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/webhook-edit'), title: 'Create Webhook', settings },
            {  name: 'webhook-edit', route: ['webhook/edit/:webhookId'], moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/webhook-edit'), title: 'Create Webhook', settings },
            {  name: 'webhook-detail', route: 'webhook/:webhookId', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/webhook-detail'), title: 'Webhook Details', settings },
            {  name: 'webhook-job-detail', route: 'webhook/:webhookId/job/:jobId', moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/job-detail'), title: 'Webhook Job Details', settings },

        ]);

        config.fallbackRoute('dashboard-program-edit');

        this.router = router;
    }

    async activate(params: any) {
        this.sort = -1;
        this.programId = parseInt(params.id, 10);
        this.refresh();
    }

    saveProgram(finish?: boolean) {
        return this.validationController.validate().then((result) => {
            if (!result.valid) {
                throw new Error('Form is not valid');
            }

            return (this.program.id ?
                this.api.programs.patch(this.program.id, this.program) :
                this.api.programs.create(this.program)
            );
        }).then((result) => {
            if (!this.program.id) {
                this.updateClones();
                return this.router.navigateToRoute('program/config', { id: result.id });
            }
            this.program = Object.assign(this.program, result);
            this.updateClones();

            this.alerts.create({
                label: 'Program saved',
                level: 'success',
                dismissable: true,
            });

            if (finish) {
                return this.router.navigateToRoute('home');
            }

            this.refresh();

        }).catch((e) => {
            this.alerts.create({
                label: e.message,
                level: 'error',
                dismissable: true,
            });
        });
    }

    delete(): Promise<void> {
        return this.dialogService.open({
            model: this.program,
            viewModel: ProgramDelete,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                throw new Error('Program was not deleted');
            }
        })
            .then(() => {
                return this.api.programs.remove(this.program.id)
                    .then(() => {
                        this.alerts.create({
                            label: 'Program Deleted',
                            level: 'success',
                            dismissable: true,
                        });
                        this.router.navigate('/');
                    })
                    .catch((e) => this.alerts.create({
                        label: e.message,
                        level: 'error',
                        dismissable: true,
                    }));
            });
    }

    @debounced(500)
    async refresh(): Promise<void> {

        if (this.program) {
            this.program.destroy();
        } else {
            this.program = new Program({}, this.api.files);
        }

        const data = this.programId ?
            await this.api.programs.get(this.programId, {
                query: { $eager: '[assetTypes, configs, surveys, roles.role]' },
            })
                .then((result) => new Program(result, this.api.files))
                .catch((e) => undefined) :
            new Program({}, this.api.files);

        Object.assign(this.program, data);
        this.updateClones();
        if (this.programId !== 0) {this.recentPrograms.manageActivity({id: this.programId, title: this.program.title} as ProgramActivity);}
        this.program.getThumbnail();
    }

    handleMediaQuery = (evt) => {
        return evt.matches ? this.isMobile = true : this.isMobile = false;
    }
}
