import { Field, ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { AlertService } from '../../../services/util/Alert';
import { ValidatedProgramConfig } from '../../../types/ProgramConfig';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { v4 as uuidv4 } from 'uuid';
import { EventAggregator } from 'aurelia-event-aggregator';
import { MDCDrawer } from '@material/drawer';
import { IDashboardConfig, IDashboardItem } from '../../../types/Dashboards';
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from '../../../components/confirm-dialog/confirm-dialog';
import cloneDeep from 'lodash.clonedeep';

const logger = LogManager.getLogger('dfp:dashboards');
@autoinject
export class Dashboard {
    config: ProgramConfig<IDashboardConfig>;
    configId: number;
    guid: string;

    @bindable editItem: any;

    editItemChanged(newValue) {

        if (!this.editItem) {
            return;
        }
        if (this.editItem.type) {
            // publish the new edit item
            this.ea.publish('edit-item', newValue);
            this.openEditor();
        } else {
            this.alerts.create({ label: 'Unknown Widget Type', level: 'warning', dismissable: true });
        }
    }

    @bindable editEnabled: boolean;
    editEnabledChanged() {
        if (!this.editEnabled) {
            this.closeEditor();
        }
    }

    dismissible: HTMLDivElement;

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
        private router: Router,
        private ea: EventAggregator,
        private dialogs: DialogService,
    ) {
    }

    async activate(params, config) {
        this.configId = ensureNumber(params.dashboardId);
        this.editEnabled = Boolean(params.edit);
        this.config = await (
            this.configId ?
                this.api.programConfigs.get(this.configId)
                    .then((config) => new ValidatedProgramConfig(config))
                    // not found
                    .catch(() => undefined) :
                new ValidatedProgramConfig({
                    label: 'New Dashboard',
                    description: 'A nifty little dashboard',
                    type: 'dashboard',
                    program_id: config.settings.program.id,
                    data: {
                        items: [
                            {
                                x: 0, y: 0, w: 6, h: 5, id: this.getId(), type: 'chart', config: {
                                    chartOptions: {
                                        type: 'bar',
                                        data: {
                                            labels: ['a', 'b', 'c'],
                                            datasets: [{
                                                label: 'Sample Dataset',
                                                data: [10, 20, 15],
                                                backgroundColor: []
                                                ,
                                            }],
                                        },
                                        options: {},
                                        plugins: [],
                                    },
                                },
                            },
                        ],
                    },
                })
        );

        // if we are creating a new dashboard, display the edit dialog for
        // the new dashboard
        if (!this.configId) {
            this.editItem = this.config;
        }
    }

    attached() {
        if (this.editEnabled) {
            this.openEditor();
        }
    }

    closeEditor() {
        this.editItem = null;
        const drawer = MDCDrawer.attachTo(this.dismissible);
        drawer.open = false;
    }

    openEditor() {
        const drawer = MDCDrawer.attachTo(this.dismissible);
        drawer.open = true;
    }

    addItem(type, config) {
        logger.debug(type, config);
        if (type == 'chart') {
            const noneField: Field = { label: 'Sample Config Field', name: 'sample', type: 'text' };
            // pass a default config to initialize the chart
            const type = config.type;
            const chartConfig = {
                type: type,
                data: {
                    labels: ['a', 'b', 'c'],
                    datasets: [{
                        label: 'Sample Dataset',
                        data: [10, 20, 15],
                        backgroundColor: ['rgb(250, 175, 63)']
                        ,
                    }],
                },
                options: {},
            };
            config = {
                storeId: undefined,
                chartOptions: chartConfig,
                customTitle: false,
                title: undefined,
                datalabels: false,
                percentLabels: false,
                categoryField: noneField, splitField: noneField, statsField: noneField,
                stat: 'Count', stacked: false, timeInterval: 'day', dateRange: '',
            };

        }

        this.config.data.items.push({ x: 0, y: 0, w: 6, h: 5, type, config, id: this.getId() });

        this.editItem = this.config.data.items[this.config.data.items.length - 1];
        this.openEditor();
    }

    getId() {
        const guid = uuidv4();
        return guid;
    }

    save() {
        const cleanConfig = cloneDeep(this.config);
        cleanConfig.data.items.forEach((item) => {
            item.highlight = false;
        });

        const id = cleanConfig.id;
        const promise = id ?
            this.api.programConfigs.patch(id, cleanConfig) :
            this.api.programConfigs.create(cleanConfig);

        return promise.then((result) => {
            this.alerts.create({
                label: 'Dashboard saved',
                level: 'success',
                dismissable: true,
            });
            if (!id) {
                this.router.navigateToRoute('dashboards', { dashboardId: result.id });
                this.ea.publish('new-dashboard', result);
            }
        }).catch((e) => {
            this.alerts.create({ label: `Error: ${e.message}`, level: 'error', dismissable: true });
        });
    }

    delete(item: IDashboardItem | ProgramConfig) {
        const isDashboard = (item as ProgramConfig).type === 'dashboard';

        if (isDashboard) {
            if (!item.id) {
                this.alerts.create({
                    label: 'Item is not yet saved',
                    level: 'warning',
                    dismissable: true,
                });
                return;
            }
        }

        return this.dialogs.open({
            viewModel: ConfirmDialog,
            model: {
                title: 'Delete',
                message: isDashboard ?
                    'Are you sure you want to delete this dashboard and all of its gadgets?' :
                    'Are you sure you want to remove this gadget?',

            },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }

            return (isDashboard ?
                this.api.programConfigs.remove(item.id) :
                Promise.resolve(this.config.data.items = this.config.data.items.filter((i) => i.id !== item.id)))
                .then(() => {

                    this.closeEditor();

                    this.alerts.create({
                        label: 'Item removed',
                        level: 'success',
                        dismissable: true,
                    });

                    if (isDashboard) {
                        this.router.navigateToRoute('program/dashboard-manager', { id: this.config.program_id });
                    }
                });

        });
    }
}
